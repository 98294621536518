
import { defineComponent } from 'vue';
import CarouselComponent from '@/components/Carousel.vue';

export default defineComponent({
  name: 'Carousel',
  components: { CarouselComponent },
  data() {
    return {
      images: [
        '/1440x580.png',
        '/1440x580.png',
        '/1440x580.png',
        '/1440x580.png',
      ],
    };
  },
});
