
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    image: {
      type: String,
      default: 'https://bulma.io/images/placeholders/1280x960.png',
    },
  },
});
