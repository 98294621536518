import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-503c6113"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      tag: "div",
      name: _ctx.transitionName,
      class: "slides-group"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: _ctx.current,
              class: "slide"
            }, [
              _createElementVNode("img", {
                src: _ctx.slides[_ctx.current]
              }, null, 8, _hoisted_2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"]),
    _createElementVNode("div", {
      class: "btn btn-prev",
      "aria-label": "Előző",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPreviousClicked()))
    }, " ❮ "),
    _createElementVNode("div", {
      class: "btn btn-next",
      "aria-label": "Következő",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNextClicked()))
    }, " ❯ ")
  ]))
}