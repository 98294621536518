import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_Services = _resolveComponent("Services")!
  const _component_Prices = _resolveComponent("Prices")!
  const _component_ContactUs = _resolveComponent("ContactUs")!
  const _component_Contacts = _resolveComponent("Contacts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Carousel),
    _createVNode(_component_AboutUs, { id: "rolunk" }),
    _createVNode(_component_Services, { id: "szolgaltatasaink" }),
    _createVNode(_component_Prices, { id: "arak" }),
    _createVNode(_component_ContactUs, { id: "keress-minket" }),
    _createVNode(_component_Contacts, { id: "kapcsolat" })
  ], 64))
}