
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  props: {
    msg: String,
  },
  data() {
    return {
      burgerActive: false,
      menuActive: false,
    };
  },
  methods: {
    onBurgerClicked() {
      this.burgerActive = !this.burgerActive;
      this.menuActive = !this.menuActive;
    },
  },
});
