import {
  createRouter, RouteRecordRaw, RouteLocationNormalized, createWebHistory,
} from 'vue-router';
import Home from '../views/home/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ajanlat',
    name: 'Ajanlat',
    component: () => import(/* webpackChunkName: "about" */ '../views/Request.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrollBehavior(to: RouteLocationNormalized, _from: any, savedPosition: any): any {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
