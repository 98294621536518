
import ContactUs from './ContactUs.vue';
import Carousel from './Carousel.vue';
import AboutUs from './AboutUs.vue';
import Services from './Services.vue';
import Prices from './Prices.vue';
import Contacts from './Contacts.vue';

export default {
  name: 'Home',
  components: {
    ContactUs, Carousel, AboutUs, Prices, Contacts, Services,
  },
};
