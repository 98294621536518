
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Carousel',
  props: {
    slides: {
      type: Array,
      default: (): number[] => [],
    },
    timer: Number,
  },
  data() {
    return {
      current: 0,
      length: 0,
      transitionName: 'slide-next',
      show: false,
      interval: 0,
    };
  },
  methods: {
    onPreviousClicked() {
      this.resetInterval();
      this.transitionName = 'slide-prev';
      if (this.current === 0) {
        this.current = this.length - 1;
        return;
      }

      this.current -= 1;
    },
    onNextClicked() {
      this.resetInterval();
      this.transitionName = 'slide-next';
      if (this.current === this.length - 1) {
        this.current = 0;
        return;
      }

      this.current += 1;
    },
    resetInterval() {
      const timeout = Number(this.timer) * 1000;
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.onNextClicked();
      }, timeout);
    },
  },
  async mounted() {
    this.show = true;
    this.length = (this.slides as any).length;

    if (this.timer) {
      this.resetInterval();
    }
  },
});

